<template>
  <div>
    <el-row>
      <el-col :span="24" style="height: 60px; line-height: 60px; background: #000000; padding: 0 25%;">
        <div class="nav">
          <img :src="head_logo" alt="logo" class="img_resize" style="margin-top: -12px; margin-right: 30px;">
          <div class="nav_menu">
            <router-link to="/home" @click.native.prevent="showGoods">车机产品</router-link>
            <router-link to="/cg2">手机支架</router-link>
            <router-link to="/user">行车记录</router-link>
            <router-link to="/g3">胎压监测</router-link>
            <router-link to="/home/support">支持</router-link>
          </div>
          <el-button type="primary" size="mini" style="margin-left: 200px; transform: translateY(-4px);">商城</el-button>
          <span class="el-icon-search search"></span>
          <!-- <span class="el-icon-search search" @mouseover="searchMove"></span> -->
          <!-- <el-input v-model="input" placeholder="请输入内容" class="ipt" @mouseleave.native="iptLeave"></el-input> -->
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" style="background: #000000;">
        <div class="grid-content bg-purple-dark">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="概述" name="first">
              <img :src="img_one" alt="1" class="aio_img_resize">
              <img :src="img_two" alt="2" class="aio_img_resize">
              <img :src="img_three" alt="3" class="aio_img_resize">
              <img :src="img_four" alt="4" class="aio_img_resize">
              <img :src="img_five" alt="5" class="aio_img_resize">
              <img :src="img_six" alt="6" class="aio_img_resize">
              <img :src="img_seven" alt="7" class="aio_img_resize">
              <img :src="img_eight" alt="8" class="aio_img_resize">
            </el-tab-pane>
            <el-tab-pane label="常见问题" name="second">

            </el-tab-pane>
            <el-tab-pane label="规格" name="third">
              <img :src="img_top" alt="1" class="aio_img_resize" style="margin-top: 60px; max-width: 500px;">
              <ul class="specs" style="padding: 0 25%;">
                <li>
                  <span class="title">概要</span>
                  <div class="info">
                    <div>
                      <p style="color: grey;">产品型号</p>
                      <p style="color: #fff;">CG-2</p>
                    </div>
                    <div>
                      <p style="color: grey;">输入参数</p>
                      <p style="color: #fff;">11-18V 3A</p>
                    </div>
                    <div>
                      <p style="color: grey;">输出参数</p>
                      <p style="color: #fff;">无线15W MAX
                        有线25W MAX</p>
                    </div>
                    <div>
                      <p style="color: grey;">无线传输距离</p>
                      <p style="color: #fff;">≤ 4MM</p>
                    </div>
                    <div>
                      <p style="color: grey;">工作环境温度</p>
                      <p style="color: #fff;">-10℃ - 40℃</p>
                    </div>
                    <div>
                      <p style="color: grey;">产品尺寸</p>
                      <p style="color: #fff;">86x76x43mm</p>
                    </div>
                  </div>
                </li>
                <li>
                  <span class="title">包装清单</span>
                  <div class="info">
                    <div>
                      <p style="color: #fff;">CG手机支架 x1</p>
                    </div>
                    <div>
                      <p style="color: #fff;">管夹变径套 x3</p>
                    </div>
                    <div>
                      <p style="color: #fff;">安装工具 x4</p>
                    </div>
                    <div>
                      <p style="color: #fff;">固定管夹 x1</p>
                    </div>
                    <div>
                      <p style="color: #fff;">说明书 x1</p>
                    </div>
                    <div>
                      <p style="color: #fff;">连接线 x1</p>
                    </div>
                  </div>
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="购买" name="fourth">定时任务补偿</el-tab-pane>
          </el-tabs>
          <div style="width: 100%;">
            <el-row>
              <el-col :span="24" style="padding: 0 25%;">
                <div class="foot_top main">
                  <dl>
                    <dt>产品</dt>
                    <dd><a href="#">AIO-5智能车机</a></dd>
                    <dd><a href="#/cg2">CG-2手机支架</a></dd>
                    <dd><a href="#">XR-3行车记录仪</a></dd>
                    <dd><a href="#/g3">G3胎压监测</a></dd>
                  </dl>
                  <dl>
                    <dt>服务支持</dt>
                    <dd><a href="#">常见问题</a></dd>
                    <dd><a href="#">教程视频</a></dd>
                    <dd><a href="#/home/support_download/down_one">下载</a></dd>
                  </dl>
                  <dl>
                    <dt>关于我们</dt>
                    <dd><a href="#">我们的故事</a></dd>
                    <dd><a href="#/home/contact">联系我们</a></dd>
                  </dl>
                  <dl>
                    <dt>业务合作</dt>
                    <dd><a href="#/home/todealer">成为经销商</a></dd>
                  </dl>
                </div>
                <div class="foot_middle main">
                  <div>
                    <el-tooltip placement="top">
                      <div slot="content">
                        <img :src="wx_erwei" alt="二维码" style="transform: translateY(600px)">
                      </div>
                      <el-button style="border: 0; background: transparent">
                        <img :src="wx" alt="wx" style="width: 38px; height: 31px;">
                      </el-button>
                    </el-tooltip>
                    <el-tooltip placement="top" style="margin-left: 50px;">
                      <div slot="content">
                        <img :src="dy_erwei" alt="二维码" style="transform: translateY(600px)">
                      </div>
                      <el-button style="border: 0; background: transparent">
                        <img :src="dy" alt="dy" style="width: 32px; height: 35px;">
                      </el-button>
                    </el-tooltip>
                    <div class="email"></div>
                  </div>
                </div>
                <div class="foot_bottom">
                  <span style="color: #5E5E5E; font-size: 20px;">版权所有©深圳市骑技科技有限公司保留所有权利。</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: 'first',
      head_logo: require('../../public/images/head_logo.png'),
      input: '', // 搜索框
      img_one: require('../../public/images/CG2/1.png'),
      img_two: require('../../public/images/CG2/2.png'),
      img_three: require('../../public/images/CG2/3.png'),
      img_four: require('../../public/images/CG2/4.png'),
      img_five: require('../../public/images/CG2/5.png'),
      img_six: require('../../public/images/CG2/6.png'),
      img_seven: require('../../public/images/CG2/7.png'),
      img_eight: require('../../public/images/CG2/8.png'),
      // tab2
      img_top: require('../../public/images/CG2/tab2/支架-场景11.2@2x.png'),
      // 尾部
      wx_erwei: require('../../public/images/wx_erwei.png'),
      wx: require('../../public/images/aioproduct/wx.png'),
      dy_erwei: require('../../public/images/aio/dy.png'),
      dy: require('../../public/images/aioproduct/dy.png')
    }
  },
  methods: {
    // 鼠标经过放大镜图标
    searchMove() {
      let ele = document.querySelector('.search')
      ele.style.display = 'none'
      let iptEle = document.querySelector('.ipt')
      iptEle.style.display = 'inline-block'
    },
    // 鼠标从输入框离开
    iptLeave() {
      let ele = document.querySelector('.search')
      let iptEle = document.querySelector('.ipt')
      ele.style.display = 'inline-block'
      iptEle.style.display = 'none'
    },
    // 子组件通知父组件显示商品按钮
    showGoods() {
      this.$emit('clickGoods')
    }
  }
}
</script>
<style lang="less" scoped>
.nav {
  position: relative;
  width: 100%;
  // background: pink;
  height: 60px;
  line-height: 60px;
  // margin-left: 20%;
  .search {
    color: #fff;
    font-size: 20px;
    margin-left: 15px;
  }
  .ipt {
    position: absolute;
    top: 0;
    right: -200px;
    display: none;
    // display: inline-block;
    width: 200px;
    margin-left: 15px;
    animation: reWidth 1s;
  }
  @keyframes reWidth {
    0% {
      width: 0;
    }
    100% {
      width: 200px;
    }
  }
  .nav_menu {
    height: 100%;
    box-sizing: border-box;
    display: inline-block;
    padding-top: 5px;
  }
  a {
    color: #fff;
    font-size: 18px;
    margin-left: 20px;
  }
}
.img_resize {
  width: auto;
  height: auto;
  max-width: 200px;
  vertical-align: middle;
}
// tab分栏
::v-deep .el-tabs__nav {
  float: right;
  margin-right: 100px;
}
.aio_img_resize {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  margin-top: -8px;
}
// tab2
.specs li {
  position: relative;
  display: block;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  text-align: left;
  span {
    position: relative;
    top: 15px;
    left: 20px;
    font-weight: 500;
    font-size: 30px;
    color: #fff;
  }
  .info {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 30%;
    width: calc(100% - 200px);
    // height: 400px;
    // background-color: purple;
    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 50%;
      height: 100px;
      // background-color: pink;
      text-align: left;
      p {
        line-height: 40px;
      }
    }
  }
}
// 尾部
.main {
  text-align: left;
  // padding: 0 20%;
}
.foot_top {
  padding-top: 80px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  dl,
  a {
    line-height: 40px;
    font-family: Alibaba PuHuiTi-Medium, Alibaba PuHuiTi;
    font-size: 15px;
    // color: #111111;
    color: grey;
  }
  dt {
    font-weight: 500;
    color: #fff;
  }
}
.foot_middle {
  height: 260px;
  padding-top: 180px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  .email {
    float: right;
    width: 300px;
    height: 200px;
    background: url('../../public/images/email.png') no-repeat;
    background-size: 100% auto;
  }
}
.foot_bottom {
  position: relative;
  height: 120px;
  // background-color: green;
  line-height: 120px;
  span {
    position: absolute;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }
}
</style>